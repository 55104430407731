import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SectionHeader from '../../components/SectionHeader'
import SectionTextTwoColumns from '../../components/SectionTextTwoColumns'
import Layout from '../../layout/Layout'

export default function Home() {
  const { headerBg } = useStaticQuery(
    graphql`
      query {
        headerBg: file(
          relativePath: {
            eq: "nasze-uslugi/transport-materialow-niebezpiecznych.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <Layout
      metaTitle="Transport materiałów niebezpiecznych"
      metaDescription="Jeśli poszukujesz pewnego i sprawdzonego partnera w logistyce międzynarodowej, który gwarantuje elastyczne i indywidualne podejście do każdego zlecenia, znajdujesz się we właściwym miejscu."
      slug="/uslugi/transport-materialow-niebezpiecznych"
    >
      <SectionHeader
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            materiałów <br />
            niebezpiecznych
          </>
        }
        titleBorder="white"
        lead="Transport ADR. Na zlecenia Klientów indywidualnych i&nbsp;firmowych realizujemy przewóz towarów i&nbsp;materiałów niebezpiecznych na terenie kraju i&nbsp;za&nbsp;granicą."
        content={
          <>
            Transport ADR jest sporym wyzwaniem logistycznym i&nbsp;wymaga
            odpowiedniego doświadczenia oraz spełnienia wielu określonych
            ustawowo warunków. Nasza firma sprostała tym wytycznym i&nbsp;na
            podstawie uzyskanych uprawnień może wykonywać tego rodzaju usługę
            (z&nbsp;wyjątkiem przewozu materiałów wybuchowych,
            promieniotwórczych i&nbsp;gazów). Jesteśmy ekspertami w&nbsp;tym
            zakresie i&nbsp;gwarantujemy Klientom najwyższą jakość przewozu oraz
            bezpieczeństwo powierzonego towaru. Korzystamy z&nbsp;nowoczesnego
            taboru i&nbsp;wysoko wykwalifikowanej kadry.
          </>
        }
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        halfBg
        bgImage={getImage(headerBg)}
      />
      <SectionTextTwoColumns
        id="czytaj-wiecej"
        smallTitle="Transport materiałów niebezpiecznych"
        title={
          <>
            Co to jest <br />
            transport ADR?
          </>
        }
        lead={
          <>
            Konwencja ADR jest międzynarodowym regulaminem dotyczącym drogowego
            przewozu towarów i&nbsp;materiałów niebezpiecznych zgodnie
            z&nbsp;zaleceniami Komisji Narodów Zjednoczonych ONZ.
          </>
        }
      >
        <p>
          Ten zbiór przepisów wskazuje, jakie zagrożenia powoduje transport
          takich ładunków oraz sposoby ich zapobiegania. Określa też, na jakich
          zasadach może odbywać się spedycja przedmiotów wybuchowych, substancji
          zapalnych, trujących, zakaźnych, żrących, czy promieniotwórczych
          i&nbsp;kiedy jest zabroniona.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport materiałów niebezpiecznych"
        title={
          <>
            Co to są materiały i&nbsp;towary
            <br /> niebezpieczne?
          </>
        }
        lead={
          <>
            Ładunki ADR to wszystkie materiały i&nbsp;towary, które mogą mieć
            szkodliwy wpływ lub powodować zagrożenie dla: ludzi, zwierząt,
            mienia ,środowiska.
          </>
        }
      >
        <p className="condensed">
          <b>
            Ze względu na rodzaj zagrożenia, jakie stwarzają ładunki
            niebezpieczne, zostały podzielone na 13 klas:
          </b>
          <ul>
            <li>klasa 1 - materiały i przedmioty wybuchowe</li>
            <li>klasa 2 - gazy</li>
            <li>klasa 3 - materiały ciekłe zapalne</li>
            <li>
              klasa 4.1 - materiały stałe zapalne, materiały samoreaktywne
              i&nbsp;materiały wybuchowe stałe odczulone
            </li>
            <li>klasa 4.2 - materiały samozapalne</li>
            <li>
              klasa 4.3 - materiały wytwarzające w&nbsp;zetknięciu z wodą gazy
              palne
            </li>
            <li>klasa 5.1 - materiały utleniające</li>
            <li>klasa 5.2 - nadtlenki organiczne</li>
            <li>klasa 6.1 - materiały trujące</li>
            <li>klasa 6.2 - materiały zakaźne</li>
            <li>klasa 7 - materiały promieniotwórcze</li>
            <li>klasa 8 - materiały żrące</li>
            <li>klasa 9 - różne materiały i&nbsp;przedmioty niebezpieczne.</li>
          </ul>
        </p>
        <p className="condensed">
          <b>Ładunki mają postać:</b>
          <ul>
            <li>stałą - materiały sypkie, granulowane, pastowate</li>
            <li>ciekłą</li>
            <li>gazową.</li>
          </ul>
        </p>
        <p className="condensed">
          <b>Do produktów stwarzających zagrożenie zalicza się m.in.:</b>
          <ul>
            <li>benzynę</li>
            <li>niektóre rozpuszczalniki i farby</li>
            <li>część nawozów sztucznych</li>
            <li>wybrane kwasy, gazy – np. propan</li>
            <li>acetylen</li>
            <li>zapalniczki</li>
            <li>akumulatory</li>
            <li>granaty</li>
            <li>sztuczne ognie.</li>
          </ul>
        </p>
        <p>
          Każdy materiał niebezpieczny otrzymuje specjalny czterocyfrowy numer
          UN, odpowiadający niebezpiecznym przymiotom danego towaru.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport materiałów niebezpiecznych"
        title={
          <>Organizacja transportu materiałów i&nbsp;towarów niebezpiecznych</>
        }
        lead={
          <>
            Jak zorganizować transport ładunków ADR? Jest to zadanie dość
            skomplikowane i wymaga odpowiedniej logistyki. Przewozy towarów
            niebezpiecznych mogą się odbywać:
            <ul>
              <li>
                w sztukach – każda musi być oznaczona nalepką ostrzegawczą
                i&nbsp;numerem UN
              </li>
              <li>
                luzem – dotyczy materiałów stałych, które stwarzają nieduże
                zagrożenie
              </li>
              <li>w cysternach.</li>
            </ul>
            Środek transportu powinien być adekwatny do właściwości towaru.
          </>
        }
      >
        <p>
          Wszystkie pojazdy przewożące ładunki niebezpieczne muszą mieć
          specjalne oznaczenia w postaci pomarańczowych tabliczek w kształcie
          prostokąta, umieszczonych z przodu i&nbsp;z&nbsp;tyłu, pionowo
          i&nbsp;prostopadle do jego osi.
        </p>
        <p className="condensed">
          <b>
            Kierowcy powinni być wyposażeni w pełną dokumentację towaru
            z&nbsp;wyszczególnionym jego:
          </b>
          <ul>
            <li>rodzajem</li>
            <li>ilością</li>
            <li>numerami UN i nalepkami</li>
            <li>oraz grupą pakowania.</li>
          </ul>
        </p>
        <p>
          Bardzo ważna w procesie organizacji przewozu jest także rola nadawcy
          ładunku niebezpiecznego. To na nim spoczywa obowiązek prawidłowego
          opakowania i&nbsp;oznakowania przesyłki oraz dokonania właściwego
          załadunku. To również on wybiera sposób i&nbsp;środek transportu,
          a&nbsp;my weryfikujemy, czy ten wybór odpowiada warunkom zgodnie
          z&nbsp;nadanym towarowi numerem.
        </p>
        <p>
          Z kolei odbiorca tego typu ładunków powinien możliwie jak najszybciej
          je przejąć, aby zminimalizować ryzyko związane z przewożeniem.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport materiałów niebezpiecznych"
        title={<>Transport ADR – postaw na sprawdzonego partnera</>}
        lead={
          <>
            Dzięki naszemu doświadczeniu potrafimy prawidłowo zorganizować
            bezpieczny transport towarów i&nbsp;materiałów niebezpiecznych, na
            podstawie wymaganych norm i&nbsp;przepisów. W&nbsp;ten sposób
            minimalizujemy ewentualne zagrożenia, które mogą spowodować wypadek
            oraz skalę zanieczyszczeń i&nbsp;zniszczeń, jakie mogą nastąpić.
          </>
        }
      >
        <p>
          Kierowcy są przeszkoleni zgodnie z&nbsp;przepisami, odpowiednio
          wyposażeni (ubrania ochronne oraz atestowany sprzęt zabezpieczający)
          i&nbsp;uprawnieni do przewozu materiałów niebezpiecznych. Podczas
          realizacji zleceń przestrzegają zasad bezpiecznego poruszania się po
          drogach oraz stosują się do wszystkich wytycznych zamieszczonych w
          konwencji.
        </p>
        <p>
          Przewozy ADR realizujemy pojazdami o&nbsp;ładowności od 1 do
          24&nbsp;t, dostosowanymi do przewozu ładunków niebezpiecznych.
        </p>
        <p>
          Nie wiesz, czy Twój ładunek kwalifikuje się do transportu towarów
          niebezpiecznych? Skorzystaj z&nbsp;doświadczenia i&nbsp;wiedzy naszych
          fachowców! Zapewniamy doradztwo ze strony certyfikowanych pracowników
          i&nbsp;pomagamy w&nbsp;zakresie doboru pojazdu do specyfiki Twojego
          ładunku. Sprawnie i&nbsp;terminowo dostarczymy każdą przesyłkę.
          Decydując się na współpracę z&nbsp;nami, zyskujesz możliwość
          skorzystania z&nbsp;różnych form przewozu, profesjonalną obsługę
          i&nbsp;kontrolę na każdym etapie zlecenia. Oferujemy także atrakcyjne
          ceny za świadczone usługi transportowe!
        </p>
      </SectionTextTwoColumns>
    </Layout>
  )
}
